<template>
  <div class="LessonDetails">
    <Navbar />
    <Header title=" تفاصيل الدرس" />
   <each-lesson></each-lesson>
    <footer-component></footer-component>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import FooterComponent from '@/components/FooterComponent';
import Header from '@/components/Header.vue';
import EachLesson from '@/components/EachLesson.vue';

export default {
  name: 'LessonDetails',
  components:{
    Navbar,
    FooterComponent,
    Header,
    EachLesson,
  }
};
</script>

<style scoped>
/* Styles specific to the about view */
</style>