import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LessonDetails from '../views/LessonDetails.vue';
import LoginView from '../views/LoginView.vue';
import Register from '../views/Register.vue';
import PassCode from '../views/PassCode.vue';
import ForgetPass from '../views/ForgetPass.vue';
import NewPass from '../views/NewPass.vue';
import Profile from '../views/Profile.vue';
import Exams from '../views/Exams.vue';
import LessonsVideo from '../views/LessonsVideo.vue';
import Lessons from '../views/Lessons.vue';
import OneExam from '../views/OneExam.vue';
import OneHw from '../views/OneHw.vue';
import HomeWork from '../views/HomeWork.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'Mekway' }
  },
  {
    path: '/lessons/:id',
    name: 'LessonDetails',
    component: LessonDetails,
    meta: { title: 'Lesson Details' }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { title: 'Login' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { title: 'register' }
  },
  {
    path: '/passcode',
    name: 'PassCode',
    component: PassCode,
    meta: { title: 'PassCode' }
  },
  {
    path: '/forgetpass',
    name: 'ForgetPass',
    component: ForgetPass,
    meta: { title: 'ForgetPass' }
  },
  {
    path: '/newPass',
    name: 'NewPass',
    component: NewPass,
    meta: { title: 'NewPass' }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { title: 'profile' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/exams',
    name: 'Exams',
    component: Exams,
    meta: { title: 'exams' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/videos/:lesson_id',
    name: 'LessonsVideo',
    component: LessonsVideo,
    meta: { title: 'Lessons Video' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/lessons',
    name: 'Lessons',
    component: Lessons,
    meta: { title: 'Lessons' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/exam/:id',
    name: 'OneExam',
    component: OneExam,
    meta: { title: 'Exam' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/homework/:id',
    name: 'OneHw',
    component: OneHw,
    meta: { title: 'Homework' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  },
  {
    path: '/homework',
    name: 'HomeWork',
    component: HomeWork,
    meta: { title: 'HomeWorks' }
    // meta: {
    //   requiresAuth: true // Optional: if you want to protect this route
    // }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});


// redirect to another website
router.beforeEach((to, from, next) => {
  if (document.referrer === '' && to.path === '/') {
    window.location.href = 'https://geomekawy.top/?v=ffa3f4e472a2';
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // Check if user is authenticated
//     if (!store.state.user) {
//       // Redirect to login page if not authenticated
//       next({ name: 'Login' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
