<template>
  <div class="profile">
    <navbar></navbar>
     <Header title="الحساب الشخصي" />
     <div class="content d-flex aside-row-complete">
      <ProfileAside @tab-selected="currentTab = $event" />
      <component :is="currentTab" class="profile-content"></component>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import ProfileAside from '@/components/ProfileAside.vue';
import ProfileData from '@/components/ProfileData.vue';
import MonthlyAchivement from '@/components/MonthlyAchivement.vue';
import PlatformValues from '@/components/PlatformValues.vue';
import ContactUs from '@/components/ContactUs.vue';
import Logout from '@/components/Logout.vue';

export default {
  name: 'Profile',
  components: {
    Header,
    Navbar,
    ProfileAside,
    ProfileData,
    MonthlyAchivement,
    PlatformValues,
    ContactUs,
    Logout
  },
  data() {
    return {
      profileImgSrc: require('@/assets/images/profile.svg'), // Path to profile image
      currentTab: 'ProfileData'
    };
  },
  // computed: {
  //   user() {
  //     // Assuming you're using Vuex to manage user state
  //     return this.$store.state.user;
  //   }
  // }
};
</script>

<style scoped>
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-details {
  text-align: center;
}
.profile-content{
  width: 100%;
}
.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}
</style>
