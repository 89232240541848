<template>
    <div class="row">
        <log-aside></log-aside>
        <code-check></code-check>
    </div>
</template>

<script>
import LogAside from '@/components/LogAside.vue';
import CodeCheck from '@/components/CodeCheck.vue';

export default {
  name: 'PassCode',
  components: {
    LogAside,
    CodeCheck
  }
};
</script>