<template>
  <footer>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-auto">
          <img :src="logoSrc" alt="Centered Image" class="img-fluid">
        </div>
      </div>
      <p class="Pfooter">خيارك الأفضل لتعلم الجولوجيا</p>
    </div>
    <div class="container">
      <div class="icon-container">
          <a href="#">
            <svg style=" margin-top: 10px; " xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
            fill="none">
            <path
              d="M10.1687 28V15.2288H14.4537L15.0966 10.2502L10.1687 10.2502V7.07206C10.1687 5.63108 10.5672 4.64907 12.6359 4.64907L15.27 4.64799V0.194922C14.8145 0.135723 13.2508 0 11.4308 0C7.63035 0 5.0285 2.31975 5.0285 6.57898L5.0285 10.2502H0.730469L0.730469 15.2288H5.0285L5.0285 28H10.1687Z"
              fill="#FAAF43" />
          </svg>
          </a>
          <a href="#">
            <svg style=" margin-top: 10px; " xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
            fill="none">
            <g clip-path="url(#clip0_30_437)">
              <path
                d="M22.1401 5.18104C21.7654 5.18104 21.4604 5.48603 21.4604 5.86081C21.4604 6.23558 21.7654 6.54057 22.1401 6.54057C22.515 6.54057 22.8199 6.23563 22.8199 5.86081C22.8199 5.48598 22.515 5.18104 22.1401 5.18104ZM14.001 7.97366C10.678 7.97366 7.97461 10.6771 7.97461 14C7.97461 17.3229 10.678 20.0264 14.001 20.0264C17.3239 20.0264 20.0274 17.323 20.0274 14.0001C20.0274 10.6771 17.3239 7.97366 14.001 7.97366Z"
                fill="#FAAF43" />
              <path
                d="M20.3242 0L7.67577 0C3.44334 0 0 3.44334 0 7.67583L0 20.3242C0 24.5567 3.44334 28 7.67577 28H20.3242C24.5567 28 28 24.5567 28 20.3242V7.67583C28 3.44334 24.5567 0 20.3242 0ZM14 21.6799C9.76527 21.6799 6.32018 18.2347 6.32018 14C6.32018 9.76527 9.76533 6.32023 14 6.32023C18.2347 6.32023 21.6799 9.76533 21.6799 14C21.6799 18.2347 18.2347 21.6799 14 21.6799ZM22.1392 8.19416C20.8526 8.19416 19.8059 7.14744 19.8059 5.86086C19.8059 4.57428 20.8526 3.52751 22.1392 3.52751C23.4258 3.52751 24.4725 4.57423 24.4725 5.8608C24.4725 7.14738 23.4258 8.19416 22.1392 8.19416Z"
                fill="#FAAF43" />
            </g>
            <defs>
              <clipPath id="clip0_30_437">
                <rect width="28" height="28" fill="white" />
              </clipPath>
            </defs>
          </svg>
          </a>
          <a href="#">
            <svg style=" margin-top: 10px;" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
            fill="none">
            <path
              d="M26.2678 7.02133C24.6699 7.02133 23.1955 6.49196 22.0115 5.59891C20.6536 4.57516 19.678 3.07344 19.3334 1.34258C19.247 0.907373 19.2016 0.46502 19.1978 0.0213318H14.6331V12.4945L14.6276 19.3266C14.6276 21.1531 13.4381 22.7019 11.7893 23.2466C11.2954 23.4099 10.7752 23.4789 10.2559 23.45C9.56899 23.4123 8.92532 23.205 8.36587 22.8703C7.17532 22.1583 6.36813 20.8666 6.34626 19.3889C6.31181 17.0795 8.17884 15.1966 10.4866 15.1966C10.9422 15.1966 11.3797 15.2709 11.7893 15.406V10.7713C11.3573 10.7073 10.9176 10.6739 10.473 10.6739C7.94696 10.6739 5.58446 11.7239 3.89571 13.6156C2.61931 15.0451 1.85368 16.8689 1.73556 18.7813C1.58079 21.2937 2.50009 23.6819 4.2829 25.4439C4.54485 25.7026 4.81993 25.9427 5.10759 26.1641C6.6361 27.3405 8.50477 27.9781 10.473 27.9781C10.9176 27.9781 11.3573 27.9453 11.7893 27.8813C13.6279 27.609 15.3243 26.7673 16.6631 25.4439C18.3081 23.8181 19.217 21.6595 19.2268 19.3621L19.2033 9.15961C19.9899 9.76635 20.8516 10.2689 21.767 10.6548C23.1993 11.2591 24.718 11.5653 26.2809 11.5648V7.02024C26.282 7.02133 26.2689 7.02133 26.2678 7.02133Z"
              fill="#FAAF43" />
          </svg>
          </a>
          <a href="#">
            <svg style="margin-top: 10px; " xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
            fill="none">
            <g clip-path="url(#clip0_30_429)">
              <path
                d="M27.6096 7.32868C27.4627 6.10263 26.3072 4.92696 25.0983 4.76946C17.7281 3.85349 10.2729 3.85349 2.90265 4.76946C1.69273 4.92662 0.537298 6.10263 0.390323 7.32868C-0.130108 11.8221 -0.130108 16.1789 0.390323 20.6713C0.537298 21.8974 1.69273 23.0741 2.90265 23.2305C10.2729 24.1465 17.7281 24.1465 25.0983 23.2305C26.3072 23.0744 27.4627 21.8974 27.6096 20.6713C28.1301 16.1793 28.1301 11.8221 27.6096 7.32868ZM11.6665 18.6675V9.3339L18.6666 14.0009L11.6665 18.6675Z"
                fill="#FAAF43" />
            </g>
            <defs>
              <clipPath id="clip0_30_429">
                <rect width="28" height="28" fill="white" />
              </clipPath>
            </defs>
          </svg>
          </a>
          <a href="#">
            <svg style="margin-top: 10px;" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
            fill="none">
            <path
              d="M27.9998 2.76117L23.5712 25.089C23.5712 25.089 22.9521 26.6372 21.2486 25.894L10.9832 18.037C12.363 16.7967 23.0669 7.17336 23.5351 6.73695C24.2586 6.06156 23.8096 5.65961 22.9685 6.17039L7.15781 16.211L1.05797 14.1575C1.05797 14.1575 0.0976526 13.8168 0.00523075 13.0736C-0.0877379 12.3304 1.08914 11.9279 1.08914 11.9279L25.9561 2.17273C25.9561 2.17273 27.9998 1.27476 27.9998 2.76117Z"
              fill="#FAAF43" />
          </svg>
          </a>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
        return {
            // The image source for your logo
            logoSrc: require('@/assets/images/navbar-logo.png')
        }
    },
}
</script>

<style scoped>
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-auto {
  position: relative;
  width: auto;
  flex: 0 0 auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.Pfooter {
  text-align: center;
  margin-top: 10px;
  color: #333;
}

/* .icon-container a {
  margin-right: 5px;
} */

.icon-container svg {
  margin-top: 10px;
  fill: #FAAF43;
  width: 28px;
  height: 28px;
}

</style>