<template>
  <div class="col-md-8 col-lg-8 right-container forms-container d-flex">
    <div class="text-center height-register">
      <h3> اهلا بك </h3>
      <p>املأ البيانات المطلوبة لتستمتع برحلتك في عالم جيو أحمد مكاوي</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group d-flex flex-column">
          <input placeholder="الاسم الاول للطالب " type="text" id="first-name" v-model="form.firstName" required>
          <div v-if="errors.first_name" class="text-danger">{{ errors.first_name }}</div>
          <input placeholder=" الاسم الثاني للطالب" type="text" id="last-name" v-model="form.lastName" required>
          <div v-if="errors.last_name" class="text-danger">{{ errors.last_name }}</div>
          <input placeholder="رقم الهاتف" type="text" id="phone-number" v-model="form.phoneNumber" required>
          <div v-if="errors.phone" class="text-danger">{{ errors.phone }}</div>
          <input placeholder="البريد الألكتروني" type="email" id="email" v-model="form.email" required>
          <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
          <input placeholder="رقم هاتف ولي الأمر" type="text" id="parent-phone-number" v-model="form.parentPhoneNumber" required>
          <div v-if="errors.father_phone" class="text-danger">{{ errors.father_phone }}</div>
          <select id="location" v-model="form.location_id" required>
            <option disabled value="">المحافظة</option>
            <option v-for="location in locations" :key="location.id" :value="location.id">
              {{ location.name }}
            </option>
          </select>
          <div v-if="errors.location_id" class="text-danger">{{ errors.location_id }}</div>
          <input placeholder="كلمة المرور" type="password" id="password" v-model="form.password" required>
          <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
          <div v-if="form.password.length > 0 && form.password.length < 8" class="text-danger">The password must be at least 8 characters.</div>
          <input placeholder="تأكيد كلمة المرور" type="password" id="password-confirm" v-model="form.passwordConfirm" required>
          <div v-if="form.password !== form.passwordConfirm" class="text-danger">Passwords do not match</div>
        </div>
        <div class="form-group btn-form-container">
          <button type="submit" class="btn btn-block orange-btn">إنشاء حساب</button>
        </div>
        <div class="text-center mt-3">
          <p>لديك حساب ؟  
            <router-link to="/login" style="color: #FFF; font-weight: 600;">تسجيل دخول</router-link>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import apiService from '../apiService';

export default {
  name: 'RegisterForm',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        parentPhoneNumber: '',
        location_id: '',
        password: '',
        passwordConfirm: ''
      },
      errors: {}, // To store error messages
      locations: []
    };
  },
  created() {
    this.fetchLocations();
  },
  methods: {
    ...mapActions(['registerUser']),
    async fetchLocations() {
      try {
        const response = await apiService.getLocations();
        if (response && response.data) {
          this.locations = response.data.data;
        } else {
          console.error('Invalid response format', response);
        }
      } catch (error) {
        console.error('Error fetching locations', error);
      }
    },
    async handleSubmit() {
      this.errors = {}; // Reset errors before validation

      // Client-side validation
      if (this.form.password.length < 8) {
        this.errors.password = ['The password must be at least 8 characters.'];
        return;
      }

      if (this.form.password !== this.form.passwordConfirm) {
        this.errors.password_confirmation = ['Passwords do not match'];
        return;
      }

      try {
        const payload = {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          phone: this.form.phoneNumber,
          email: this.form.email,
          father_phone: this.form.parentPhoneNumber,
          location_id: this.form.location_id,
          password: this.form.password,
          password_confirmation: this.form.passwordConfirm,
        };
        await this.registerUser(payload); // Dispatch the action to register the user
        // Only redirect if there are no errors
        if (!Object.keys(this.errors).length) {
          this.$router.push('/profile'); // Redirect to profile page after successful registration
        }
      } catch (error) {
        console.error('Error submitting form', error);
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors; // Capture validation errors from the API response
          // Display the first error message in an alert
          // const firstErrorKey = Object.keys(this.errors)[0];
          // const firstErrorMessage = this.errors[firstErrorKey][0];
          // alert(firstErrorMessage);
        }
      }
    }
  }
};
</script>


<style scoped>
.text-center.mt-3 p a {
  color: #FFF;
  font-weight: 600;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Cairo', sans-serif;
}
.text-danger {
  color: rgb(250, 175, 67) !important;
  font-size: 12px;
  background: #fff;
  padding: 4px;
    border-radius: 5px;
}
.height-register{
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>