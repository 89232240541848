<template>
  <div class="container-fluid" style="margin-top: 64px;">
    <div class="centered-container">
      <div class="w3-container">
        <h3 class="title-center">حابب تشترك معانا</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 mb-5" v-for="(item, index) in steps" :key="index">
        <p class="custom-title text-center">
          {{ item.title }}
          <img :src="item.imgSrc" class="inline-img">
        </p>
        <p class="custom-text text-center">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoveToSubscribe',
  data() {
    return {
      steps: [
        {
          title: 'اضغط علي زر “تسجيل الدخول “',
          imgSrc: require('@/assets/images/home-arrow.png'),
          description: 'ابحث عن زر “تسجيل الدخول” واضغط عليه.'
        },
        {
          title: 'سجل بياناتك الشخصية',
          imgSrc: require('@/assets/images/grad-home.png'),
          description: ' أدخل بريدك الإلكتروني وكلمة المرور واذا كنت جديدًا أنشئ حسابًا جديدًا .'
        },
        {
          title: 'ادفع بالطريقة المريحة',
          imgSrc: require('@/assets/images/home-money.png'),
          description: 'اختر الخطة ووسيلة الدفع المناسبة.و أكمل عملية الدفع وتأكيد الاشتراك.'
        }
      ]
    };
  }
};
</script>

<style scoped>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-center {
  color: #FAAF43;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 60px;
}

.custom-title {
  color: #0D0D0D;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
}

.custom-text {
  color: #ADADAD;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  /* line-height: 60px; */
}

.inline-img {
  display: inline;
}
</style>
