<template>
  <div class="header">
    <div class="hero">
      <h4 class="title">{{ title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.header{
  width: 100%;
}
.hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 39%) 0%, rgba(0, 0, 0, 0.80) 100%), url(@/assets/images/main-headerbg.png) lightgray 50% / cover no-repeat;
  height: 518px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  color: #FAAF43;
  font-family: Cairo;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 39px;
  /* margin-top: 200px; */
}
</style>
