<template>
  <div class="container-fluid" style="margin-top: 64px;">
    <h3 class="w3-center h3home">
      ليه تشترك  <span class="highlighted-text">معانا</span>
    </h3>
    <div class="row justify-content-center">
      <div v-for="(item, index) in features" :key="index" class="col-lg-4 mb-5 feature-item">
        <img :src="item.imageSrc" :alt="item.altText" class="feature-icon">
        <p class="feature-title">{{ item.title }}</p>
        <p class="feature-description">{{ item.description }}</p>
        <hr v-if="item.showDivider" class="feature-divider">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyChooseUs',
  data() {
    return {
      features: [
        {
          imageSrc: require('@/assets/images/Group 5.png'),
          altText: 'اختبارات الدروس',
          title: 'اختبارات الدروس',
          description: 'استعد لاختبارات متنوعة تغطي جميع المواضيع الدراسية لضمان فهمك الكامل وتحقيق أفضل النتائج.',
          showDivider: false
        },
        {
          imageSrc: require('@/assets/images/Group 6.png'),
          altText: 'بنك الأسئلة',
          title: 'بنك الأسئلة',
          description: 'استفد من مكتبة شاملة من الأسئلة المتنوعة التي تساعدك على التدرب والتحضير للاختبارات بفعالية.',
          showDivider: true
        },
        {
          imageSrc: require('@/assets/images/Group 7.png'),
          altText: 'واجبات متعددة',
          title: 'واجبات متعددة',
          description: 'قم بحل واجبات متعددة لممارسة ما تعلمته وتعزيز مهاراتك الدراسية من خلال تحديات يومية وأسبوعية.',
          showDivider: false
        }
      ]
    };
  }
};
</script>

<style scoped>
.highlighted-text {
  color: #FAAF43;
  font-size: 42px;
  font-weight: 700;
}

/* .feature-item {
  margin-top: 80px;
} */

.feature-icon {
  width: auto;
  height: auto;
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.feature-title {
  color: #0D0D0D;
  font-family: Cairo;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.feature-description {
  text-align: center;
  color: #ADADAD;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.feature-divider {
  background: linear-gradient(90deg, #FAAF43 50.21%, #FFF 82.34%);
  height: 1px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
</style>
