<template>
  <div class="all-lessons">
    <div class="month-lesson-container" v-for="course in courses" :key="course.id">
      <div>
        <img class="img" alt="Image" src="https://c.animaapp.com/1ALtF0HJ/img/image-1-4@2x.png" />
        <h3>{{ course.name }}</h3>
        <p>{{ course.description }}</p>
        <h6>{{ course.price }} </h6>
      </div>
      <div class="text-center">
        <!-- Pass courseId to the openModal method -->
        <button @click="openModal(course.id)">اشترك في الكورس</button>

        <!-- Pass selectedCourseId as a prop to the modal -->
        <PaymentMethodModal :isOpen="isModalOpen" :courseId="selectedCourseId" @close="closeModal" />
      </div>
      <div class="todetails">
        <router-link :to="{ name:'LessonDetails', params:{id: course.id} }">تفاصيل الكورس</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodModal from './PaymentMethodModal.vue';
import apiService from '@/apiService';

export default {
  name: 'MonthlyLesson',
  components: {
    PaymentMethodModal,
  },
  data() {
    return {
      isModalOpen: false, // Control whether the modal is open or closed
      courses: [], // Store all courses here
      selectedCourseId: null, // Store the selected course id
    };
  },
  mounted() {
    apiService.getCourses()
      .then(response => {
        this.courses = response.data.data;
      })
      .catch(error => {
        console.error("Error fetching courses:", error);
      });
  },
  methods: {
    openModal(courseId) {
      this.selectedCourseId = courseId; // Set the selected course id when opening the modal
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  }
};
</script>


<style scoped>
.modal-container {
  text-align: center;
}

.month-lesson-container {
  border-radius: 32px;
  border: 1px solid #FFF;
  background: #FAFAFA;
  position: relative;
  margin-top: 90px;
  /* width: 33.33%; */
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px;
}

.month-lesson-container h3 {
  color: #000;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 25px;
}

.month-lesson-container p {
  color: #8C8C8C;
  text-align: right;
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.month-lesson-container h6 {
  color: #FAAF43;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.month-lesson-container button {
  border-radius: 10px;
  background: #FAAF43;
  color: #FFF;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  width: 100%;
}

.month-lesson-container img {
  width: 25%;
  position: absolute;
  top: -7%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.rectangle-4 {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 200px;
  background: #f4f4f4;
  border-radius: 16px;
}

.todetails {
  margin: 10px 0;
}
.todetails a{
  font-size: 18px;
  color: #FAAF43;
  border: 1px solid #FAAF43;
  padding: 15px 20px;
  width: 100%;
  border-radius: 8px;
  display: block;
  text-align: center;
  font-weight: 600;
}

.img {
  width: 100%;
  border-radius: 16px;
  margin-top: 20px;
}

.text-wrapper-10 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.text-wrapper-11 {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.line-2 {
  width: 100%;
  margin: 20px 0;
}

.text-wrapper-12 {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin: 20px 0;
}

.rectangle-5 {
  width: 100%;
  height: 50px;
  background: #FAAF43;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-wrapper-13 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
</style>
