<template>
  <div class="home-view">
    <Navbar />
    <Header title="  فيديو الدرس " />
    <lesson-video></lesson-video>
    <footer-component></footer-component>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import FooterComponent from '@/components/FooterComponent';
import LessonVideo from '@/components/LessonVideo.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'LessonsVideo',
  components: {
    Navbar,
    FooterComponent,
    LessonVideo,
    Header
  }
};
</script>

<style scoped>
/* Styles specific to the home view */
</style>