<template>
  <div class="navbar-container">
    <div class="right-cta-menu text-right">
      <div class="ml-auto">
        <div class="logo">
          <a href="#">
            <img :src="logoSrc" alt="Logo" />
          </a>
        </div>
        <a href="#" class="site-menu-toggle mt-lg-2 ml-3" @click.prevent="toggleSideMenu">
          <span class="icon-menu h3 m-0 p-0 mt-2">
            <svg class="menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
            </svg>
          </span>
        </a>
      </div>
    </div>
    <nav class="mx-auto site-navigation w-50">
      <ul class="site-menu js-clone-nav ml-0 pl-0 d-flex w-100 gap-5 justify-content-center">
        <li>
          <router-link to="/" exact-active-class="active-link">الرئيسية</router-link>
        </li>
        <li>
          <router-link to="/lessons" exact-active-class="active-link">الكورسات</router-link>
        </li>
        <li>
          <router-link to="/exams" exact-active-class="active-link">الامتحانات</router-link>
        </li>
        <li>
          <router-link to="/homework" exact-active-class="active-link">الواجبات</router-link>
        </li>
      </ul>
    </nav>
    <div class="site-logo">
      <a>
        <img v-if="isLoggedIn" :src="profileImgSrc" alt="Profile" class="profile-img" @click="goToProfilePage" />
        <button v-else class="butnav" @click="goToLoginPage">ابدأ معانا</button>
      </a>
    </div>
    <div v-if="isSideMenuOpen" class="side-menu">
      <button @click="toggleSideMenu" class="close-menu-button">X</button>
      <ul>
        <li @click="toggleSideMenu"><router-link to="/" exact-active-class="active-link">الرئيسية</router-link></li>
        <li @click="toggleSideMenu"><router-link to="/lessons" exact-active-class="active-link">الكورسات</router-link></li>
        <li @click="toggleSideMenu"><router-link to="/exams" exact-active-class="active-link">الامتحانات</router-link></li>
        <li @click="toggleSideMenu"><router-link to="/homework" exact-active-class="active-link">الواجبات</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      logoSrc: require('@/assets/images/navbar-logo.png'),
      profileImgSrc: require('@/assets/images/profile.svg'),
      isSideMenuOpen: false
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    goToLoginPage() {
      this.$router.push({ name: 'Login' });
    },
    goToProfilePage() {
      this.$router.push({ name: 'Profile' });
    },
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
    }
  }
};
</script>

<style scoped>
.menu-icon {
  fill: #FAAF43; /* Change the color to your desired color */
}
.right-cta-menu {
  display: flex;
  align-items: center;
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 50px;
  position: absolute;
}

.site-navigation {
  display: none;
}

.site-navigation .active-link {
  color: #FAAF43;
}

.site-navigation ul {
  list-style: none;
}

.site-navigation a {
  color: #fff;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.site-menu-toggle {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 10px;
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  z-index: 1000;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu li {
  margin: 20px 0;
}

.side-menu a {
  color: #fff;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
}

.icon-menu svg {
  width: 30px;
}

.close-menu-button {
  position: absolute;
  right: 10px;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .site-navigation {
    display: flex;
  }
}

@media (max-width: 991px) {
  .site-menu-toggle {
    display: inline-block;
  }
  .navbar-container {
    display: block;
    text-align: center;
  }
  .right-cta-menu {
    display: block;
  }
  .site-logo {
    position: absolute;
    top: 35px;
    left: 5px;
  }
}
</style>
