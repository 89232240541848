<template>
  <div class="login-view">
    <div class="row">
      <log-aside></log-aside>
    <LoginForm />
    </div>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import LogAside from '@/components/LogAside.vue';

export default {
  name: 'LoginView',
  components: {
    LoginForm,
    LogAside
  }
};
</script>

<style scoped>
/* Styles specific to the login view */
</style>