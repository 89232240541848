<template>
    <div class="one-exam">
      <navbar></navbar>
       <Header title=" الواجبات" />
       <OpenHw></OpenHw>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue';
  import Navbar from '@/components/Navbar.vue';
  import OpenHw from '@/components/OpenHw.vue';
  
  export default {
    name: 'OneHw',
    components: {
      Header,
      Navbar,
      OpenHw
    }
  };
  </script>