<template>
  <div class="home-view">
    <Navbar />
    <Header title="الكورسات المتاحة " />
    <monthly-lesson></monthly-lesson>
    <footer-component></footer-component>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import FooterComponent from '@/components/FooterComponent';
import MonthlyLesson from '@/components/MonthlyLesson.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'Lessons',
  components: {
    Navbar,
    FooterComponent,
    MonthlyLesson,
    Header
  }
};
</script>

<style scoped>
/* Styles specific to the home view */
</style>