<template>
    <div class="row">
        <log-aside></log-aside>
        <new-password></new-password>
    </div>
</template>

<script>
import LogAside from '@/components/LogAside.vue';
import NewPassword from '@/components/NewPassword.vue';

export default {
  name: 'NewPass',
  components: {
    LogAside,
    NewPassword
  }
};
</script>