<template>
  <div class="col-md-8 col-lg-8 right-container forms-container">
    <div class="text-center mt-5">
      <h3>تغيير كلمة المرور</h3>
      <p>املأ البيانات المطلوبة لتستمتع برحلتك في عالم جيو أحمد مكاوي</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group d-flex flex-row code-box">
          <input placeholder="البريد الالكتروني" type="email" class="input1" id="username" v-model="email" required>
        </div>
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group btn-form-container">
          <button type="submit" class="btn btn-block orange-btn">إرسال الكود</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService'; // Ensure this path is correct

export default {
  name: 'ForgetPassword',
  data() {
    return {
      email: '',
      showSuccessModal: false,
      errorMessage: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await apiService.sendCode(this.email);
        console.log('OTP sent successfully:', response.data);
        this.showSuccessModal = true; // Show a success message or modal
        this.$router.push({ path: '/passcode', query: { email: this.email } }); // Redirect to the passcode page with email
      } catch (error) {
        console.error('Error sending OTP:', error);
        if (error.response && error.response.data && error.response.data.errors) {
          this.errorMessage = error.response.data.errors.email ? error.response.data.errors.email[0] : 'حدث خطأ. حاول مرة أخرى.';
        } else {
          this.errorMessage = 'حدث خطأ. حاول مرة أخرى.';
        }
      }
    }
  }
};
</script>

<style scoped>
.forms-container {
  height: 100vh;
}
</style>
