<template>
  <div class="w-100">
    <div class="accordion accordion-flush exam-acc" id="accordionFlushExample">
      <div v-for="(exam, index) in exams" :key="exam.id" class="accordion-item">
        <h2 class="accordion-header" :id="'flush-heading' + index">
          <button
            class="accordion-button collapsed"
            type="button"
            :data-bs-toggle="'collapse'"
            :data-bs-target="'#flush-collapse' + index"
            aria-expanded="false"
            :aria-controls="'flush-collapse' + index"
          >
            <div class="exam-title d-flex">
              <h6>{{ exam.name }}</h6>
            </div>
          </button>
        </h2>
        <div
          :id="'flush-collapse' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'flush-heading' + index"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body exam-desc">
            <!-- <p>{{ exam.instructions }}</p> -->
            <div class="d-flex gap-4 numbers-accordion">
              <!-- <div class="d-flex align-items-center">
                <img :src="clipboard" alt="Clipboard Icon" />
                <p>عدد الأسئلة :  دقيقة</p>
              </div> -->
              <div class="d-flex align-items-center">
                <img :src="time" alt="Time Icon" />
                <p>مدة الأمتحان : {{ exam.duration }} دقيقة</p>
              </div>
              <!-- <div class="d-flex align-items-center">
                <img :src="trick" alt="Trick Icon" />
                <p>عدد المجتازين للأمتحان : 1000</p>
              </div> -->
              <div class="exam-start">
                <router-link :to="{ name: 'OneExam', params: { id: exam.id } }">بدء الامتحان</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import apiService from '@/apiService';

export default {
  name: 'NewExams',
  data() {
    return {
      clipboard: require('@/assets/images/clipboard-text.svg'),
      trick: require('@/assets/images/profile-tick.svg'),
      time: require('@/assets/images/time.svg'),
      exams: [],
    };
  },
  mounted() {
    // Fetch exams data from API when component is mounted
    apiService.getAllExams().then(response => {
      this.exams = response.data.data; // Assuming response.data.data contains the array of exams
    }).catch(error => {
      console.error("Error fetching exams:", error);
    });

    // Optional: Handle Bootstrap accordion collapse functionality
    const Collapse = require('bootstrap/js/dist/collapse');
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach((button) => {
      button.addEventListener('click', function () {
        const collapseElement = document.querySelector(button.getAttribute('data-bs-target'));
        if (collapseElement.classList.contains('show')) {
          Collapse.getInstance(collapseElement).hide();
        } else {
          Collapse.getOrCreateInstance(collapseElement).show();
        }
      });
    });
  },
};


</script>

<style scoped>
.card-header .btn {
  display: flex;
  align-items: center;
}

.svgexam {
  margin-right: 10px;
}

.info-text {
  color: #5b5b5b;
  text-align: right;
  display: inline;
  font-family: Cairo, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.success-text {
  color: #57d12c;
  text-align: right;
  display: inline;
  font-family: Cairo, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.icon {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}
.exams-accordion{
  width: 100%;
}
</style>
