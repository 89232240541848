<template>
  <div class="contact-container">
    <h4 class="content-title">تواصل معنا في اي وقت تحتاجه</h4>
    <input type="text" placeholder="مالذي تحتاج اليه" class="message-input">
    <button class="send-button">إرسال</button>
  </div>
</template>

<script>
export default {
  name: 'ContactUs'
};
</script>

<style scoped>
.contact-container {
  width: 90%;
  height: 452px;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.content-title {
  color: #000;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
}

.message-input {
  border-radius: 16px;
  background: #FAFAFA;
  width: 96%;
  border: 1px none;
  display: block;
  height: 236px;
  flex-shrink: 0;
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.send-button {
  color: #FFF;
  text-align: center;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;
  border: 1px solid #FFF;
  border-radius: 16px;
  background: #FAAF43;
  width: 155px;
  height: 60px;
  flex-shrink: 0;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 20px;
}
</style>
