<template>
  <div class="lesson-video-container">
    <div class="d-flex justify-content-center m-4 w-100">
      <!-- Display the video -->
      <iframe 
        v-if="videoLink" 
        :key="videoLink"
        :src="videoLink" 
        frameborder="0" 
        allowfullscreen
        width="100%" 
        height="480px"
      ></iframe>
    </div>
    <!-- <div class="lesson-details">
      <h5 class="lecture-title">
        svg
        خد ملاحظات عن المحاضرة
      </h5>
      <p class="lecture-description">
       {{ lectureDescription }}
       هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
      </p>
      <div class="text-start">
        <button class="btn-save">حفظ</button>
      </div>
    </div> -->
    <button @click="nextVideo" class="btn-next align-self-start"> الفيديو التالي</button>
  </div>

  <custom-modal
      :visible="isModalOpen"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeModal"
      @confirm="closeModal"
    />
</template>
<script>
import apiService from '@/apiService';
import CustomModal from './CustomModal.vue';

export default {
  name: 'LessonVideo',
  data() {
    return {
      videoLink: '', // Store the current video link
      lectureDescription: '', // Store the current lecture description
      videos: [], // Store the list of videos
      currentIndex: 0, // Track the current video index
      isModalOpen: false, // For controlling the modal visibility
      modalTitle: '', // To store the modal title dynamically
      modalMessage: '', // To store the modal message dynamically
    };
  },
  components: {
    CustomModal
  },
  mounted() {
    const lessonId = this.$route.params.lesson_id;
    this.getVideos(lessonId); // Fetch videos for the lesson
  },
  methods: {
    async getVideos(lessonId) {
      try {
        const response = await apiService.getVideos(lessonId); // Fetch the video data
        if (response.data && response.data.data) {
          this.videos = response.data.data; // Store the videos in the component's data
          this.showVideo(0); // Display the first video initially
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    },
    showVideo(index) {
      if (this.videos[index]) {
        const lesson = this.videos[index];
        this.videoLink = this.convertToEmbedUrl(lesson.link);
        this.lectureDescription = lesson.name;
        this.currentIndex = index;
      }
    },
    nextVideo() {
      if (this.currentIndex < this.videos.length - 1) {
        this.currentIndex++; // Move to the next video
        this.showVideo(this.currentIndex); // Display the next video
      } else {
        // this.modalTitle = 'نجاح';
        this.modalMessage = 'لا توجد فيديوهات اخرى';
        this.openModal(); // No more videos
      }
    },
    convertToEmbedUrl(link) {
      // Check if it's a YouTube link and convert it to embed format
      const youtubeRegex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = link.match(youtubeRegex);
      if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}`;
      }
      return link; // Return the original link if it's not a YouTube video
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>




<style scoped>
.lesson-video-container {
  flex-shrink: 0;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 7vw;
}

.lecture-title {
  color: #000;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;

}

.lecture-icon {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  margin-left: auto;
}
.lesson-details{
  border-radius: 32px;
  background: #FAFAFA;
  padding: 20px;
  margin-bottom: 20px;
}
.lecture-description {
  margin: 20px 0;
  color: #8C8C8C;
  text-align: right;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-save,.btn-next{
  /* width: 100%; */
  padding: 10px 45px;
  background-color: #FAAF43;
  border: none;
  color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
