<template>
    <div v-if="visible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h5>{{ title }}</h5>
        <p>{{ message }}</p>
        <button @click="confirm">OK</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: Boolean,
      title: String,
      message: String
    },
    methods: {
      confirm() {
        this.$emit('confirm');
      },
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 50%;
  }
  .modal-content button{
    background: rgb(250, 175, 67);
    color: #fff;
    width: fit-content;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    align-self: center;
  }
  .modal-content p{
    font-weight: 600;
  }
  </style>
  