import { createStore } from 'vuex';
import apiService from './apiService';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    studentToken: null,
    userProfile: null,
  },
  mutations: {
    SET_STUDENT_TOKEN(state, token) {
      state.studentToken = token;
    },
    SET_USER_PROFILE(state, profile) {
      state.userProfile = profile;
      console.log('Profile data committed to state:', profile); // Log to verify profile data
    },
    CLEAR_USER_DATA(state) {
      state.userProfile = null; // Clear user profile
      state.studentToken = null; // Clear token state
    }
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      try {
        const response = await apiService.login(payload);
        const token = response.data.data.token; // Extract the token from the response
        commit('SET_STUDENT_TOKEN', token);
        console.log('Token after login:', token); // Log to verify token storage
        // Fetch the profile after login
        await dispatch('fetchUserProfile');
      } catch (error) {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        throw error; // Ensure the error is thrown so it can be caught in the component
      }
    },
    async fetchUserProfile({ commit, state }) {
      if (!state.studentToken) {
        console.error('No token found in store.');
        return;
      }
      try {
        const response = await apiService.getProfile();
        commit('SET_USER_PROFILE', response.data.data); // Ensure correct data structure
      } catch (error) {
        console.error('Error fetching user profile:', error.response ? error.response.data : error.message);
      }
    },
    async registerUser({ commit, dispatch }, payload) {
      try {
        const response = await apiService.signUp(payload);
        const token = response.data.data.token; // Extract the token from the response
        commit('SET_STUDENT_TOKEN', token); // Assuming the API returns a token on registration
        commit('SET_USER_PROFILE', response.data.data); // Assuming the API returns the user profile data
        console.log('Token after registration:', token); // Log to verify token storage
        await dispatch('fetchUserProfile');
      } catch (error) {
        console.error('Error during registration:', error.response ? error.response.data : error.message);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        await apiService.logout(); // Make an API call to logout the user if needed
        commit('CLEAR_USER_DATA'); // Clear user data from the store
        localStorage.removeItem('user-token'); // Remove token from local storage if you are storing it
      } catch (error) {
        console.error('Error during logout:', error);
        throw error;
      }
    }
  },
  getters: {
    isLoggedIn: state => !!state.studentToken,
    userProfile: state => state.userProfile,
  },
  plugins: [
    createPersistedState({
      key: 'studentAuthData', // Optional: rename the key used in localStorage
      paths: ['studentToken', 'userProfile'], // Specify the state to persist
    }),
  ],
});
