<template>
  <div class="col-md-8 forms-container">
    <div>
      <!-- Right Container with 8 Columns -->
      <div class="col-md-8 col-lg-8 p-0">
        <div class="text-white d-flex flex-column justify-content-center" :style="rightContainerStyle">
          <h3 class="h3log">مرحباً بعودتك</h3>
          <p class="p2login">املأ البيانات المطلوبة لتستمتع برحلتك في عالم جيو أحمد مكاوي</p>

          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <input placeholder="رقم الهاتف" type="text" class="form-control" v-model="phone">
            </div>
            <div class="form-group">
              <input placeholder="كلمة المرور" type="password" class="form-control" v-model="password">
            </div>
            <div class="d-flex justify-content-between mb-3">
              <router-link to="/forgetpass" class="forgot-pass text-white" style="font-family: Cairo; font-size: 20px; font-weight: 600;">نسيت كلمة المرور؟</router-link>
            </div>
            <button type="submit" class="btn btn-block" :style="loginButtonStyle">تسجيل الدخول</button>
          </form>

          <!-- Error messages -->
          <div v-if="errorMessages.length" class="alert alert-danger mt-3">
            <ul>
              <li v-for="(message, index) in errorMessages" :key="index">{{ message }}</li>
            </ul>
          </div>

          <div class="text-center mt-3">
            <p style="font-family: Cairo'; font-size: 23px; font-weight: 500;">ليس لديك حساب؟
              <router-link to="/register" class="text-white" style="font-size: 23px;">
                إنشاء حساب
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      phone: '',
      password: '',
      errorMessages: [],
    };
  },
  computed: {
    rightContainerStyle() {
      return {
        backgroundImage: "url('public/images/rectangle-380.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh"
      };
    },
    loginButtonStyle() {
      return {
        borderRadius: "8px",
        background: "#FAAF43",
        backdropFilter: "blur(40px)",
        color: "#FFF",
        fontFamily: "Cairo",
        fontSize: "22px",
        fontWeight: "600",
        textDecoration: "none"
      };
    }
  },
  methods: {
  ...mapActions(['login']),
  async handleSubmit() {
    this.errorMessages = []; // Clear any existing error messages
    try {
      const payload = {
        phone: this.phone,
        password: this.password,
      };
      await this.login(payload);
      // Redirect to profile page after successful login
      this.$router.push('/profile');
      // Redirect to another website after successful login
      // window.location.href = "https://geomekawy.top/?v=ffa3f4e472a2";
    } catch (error) {
      console.log('Error object:', error); // Log the entire error object
      if (error.response) {
        console.log('Error response:', error.response); // Log the error response
        if (error.response.data && error.response.data.errors) {
          const errorMessage = error.response.data.errors.error[0];
          console.log('Specific error message:', errorMessage); // Log the specific error message
          // alert(errorMessage); // Display the specific error message in an alert
          this.errorMessages = [errorMessage];
        } else {
          const genericErrorMessage = 'An unknown error occurred. Please try again.';
          console.log('Generic error message:', genericErrorMessage);
          // alert(genericErrorMessage); // Display a generic error message in an alert
          this.errorMessages = [genericErrorMessage];
        }
      } else {
        const genericErrorMessage = 'An unknown error occurred. Please try again.';
        console.log('Generic error message:', genericErrorMessage);
        alert(genericErrorMessage); // Display a generic error message in an alert
        this.errorMessages = [genericErrorMessage];
      }
    }
  }
}


};
</script>

<style scoped>
.p2login {
  font-size: 20px;
  font-weight: 500;
}

.h3log {
  font-size: 36px;
  font-weight: 600;
}

.plogin {
  font-size: 28px;
  font-weight: 600;
}

.btn-block {
  display: block;
  width: 100%;
}

.alert {
  background-color: #fff;
  /* border-color: #f5c6cb; */
  color: rgb(250, 175, 67);
  text-align: center;
}
.alert ul{
  list-style: none;
  margin-bottom: 0;
}
</style>
