<template>
  <div class="col-md-8 col-lg-8 right-container forms-container">
    <div class="text-center mt-5">
      <h3>كود التحقق</h3>
      <p>املأ البيانات المطلوبة لتستمتع برحلتك في عالم جيو أحمد مكاوي</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group d-flex flex-row-reverse code-box">
          <input type="text" v-for="(code, index) in codes" :key="index" v-model="codes[index]" maxlength="1" @input="handleInput($event, index)" @keydown="handleKeydown($event, index)" dir="Rtl" class="otp-input" ref="codeInputs">
        </div>
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group btn-form-container">
          <button type="submit" class="btn btn-block orange-btn"> تأكيد</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import apiService from "@/apiService"; // Ensure this path is correct

export default {
  name: "CodeCheck",
  data() {
    return {
      email: this.$route.query.email || "",
      codes: ["", "", "", "", "", ""],
      errorMessage: ""
    };
  },
  methods: {
    handleInput(event, index) {
      const value = event.target.value;
      // Ensure only English digits are allowed
      const englishDigit = value.replace(/[^0-9]/g, '');
      if (englishDigit.length > 1) {
        this.codes[index] = englishDigit[0];
      } else {
        this.codes[index] = englishDigit;
      }
      if (this.codes[index] && index < this.codes.length - 1) {
        this.$refs.codeInputs[index + 1].focus();
      }
    },
    handleKeydown(event, index) {
      if (event.key === 'Backspace' && !this.codes[index] && index > 0) {
        this.$refs.codeInputs[index - 1].focus();
      }
    },
    async handleSubmit() {
      const verificationCode = this.codes.join("");
      try {
        const response = await apiService.confirmCode({
          email: this.email,
          otp: verificationCode
        });
        console.log("Code confirmed successfully:", response.data);
        // Redirect to the new password page with the token
        this.$router.push({
          path: "/newPass",
          query: { email: this.email, token: response.data.data }
        });
      } catch (error) {
        console.error("Error confirming code:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.otp
            ? error.response.data.errors.otp[0]
            : "حدث خطأ. حاول مرة أخرى.";
        } else {
          this.errorMessage = "حدث خطأ. حاول مرة أخرى.";
        }
      }
    }
  }
};
</script>

<style scoped>
.code-box input {
  margin: 7px;
  text-align: center;
  font-size: 24px;
  direction: ltr;
}
.otp-input {
  font-family: "Arial", sans-serif; /* Use a font that clearly differentiates between Arabic and English digits */
}
.forms-container {
  height: 100vh;
}

</style>
