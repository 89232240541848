<template>
  <div class="d-flex flex-column w-100">
    <div class="old-exam-container" v-for="(homework) in homeworks" :key="homework.homework_id">
      <div class="exam-title">
        <img :src="rightSrc" alt="Logo" />
        <p>{{ homework.homework_name }} </p>
      </div>
      <button class="resultbtn" @click="openModal(homework.homework_id)">عرض النتيجة</button>
      <HwResultModal :isOpen="isModalOpen" :homeworkId="selectedHomeworkId" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import HwResultModal from './HwResultModal.vue';
import apiService from '@/apiService';

export default {
  name: 'CompletedHw',
  components: {
    HwResultModal
  },
  data() {
    return {
      isModalOpen: false,
      selectedHomeworkId: null,
      rightSrc: require('@/assets/images/right-green.svg'),
      homeworks: []
    };
  },
  methods: {
    openModal(homeworkId) {
      this.isModalOpen = true;
      this.selectedHomeworkId = homeworkId;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    // Fetch exams data from API when component is mounted
    apiService.getCompletedEHws().then(response => {
      this.homeworks = response.data;
    }).catch(error => {
      console.error("Error fetching homeworks:", error);
    });
  },
};
</script>

  

<style scoped>
    .old-exam-container{
        display: flex;
        height: fit-content;
        border-radius: 32px;
        background: #FAFAFA;
        padding: 20px 40px;
        width: 80%;
        margin: 20px;
        justify-content: space-between;
    }
    .resultbtn{
        border-radius: 16px;
        background: #FAAF43;
        color: #FFF;
        font-family: Cairo;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        padding: 5px 30px;
        border: 0;
    }
    .exam-title{
        display: flex;
        width: 60%;
    }
    .exam-title p{
        color: #000;
        font-family: Cairo;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        /* margin: 0; */
        margin-bottom: 0;
    }
</style>
