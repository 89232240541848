import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './global.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS with Popper.js
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'; // Ensure jQuery is imported
// import 'vuetify/dist/vuetify.min.css';

// Make jQuery available globally
window.$ = $;
window.jQuery = $;

const app = createApp(App);
app.use(store);
app.use(router);
app.mount('#app');



