<template>
    <div class="row register-row">
        <log-aside></log-aside>
        <register-form></register-form>
    </div>
</template>

<script>
import LogAside from '@/components/LogAside.vue';
import RegisterForm from '@/components/RegisterForm.vue';

export default {
  name: 'Register',
  components: {
    LogAside,
    RegisterForm
  }
};
</script>

<style scoped>
.register-row{
  height: 100vh;
}
</style>