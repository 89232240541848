<template>
    <div class="col-md-8 col-lg-8 right-container forms-container">
      <div class="text-center mt-5">
        <h3> كلمة المرور الجديدة </h3>
        <p>املأ البيانات المطلوبة لتستمتع برحلتك في عالم جيو أحمد مكاوي</p>
        <form @submit.prevent="handleSubmit">
          <div class="form-group d-flex flex-row code-box position-relative">
            <input :type="showPassword ? 'text' : 'password'" placeholder="كلمة المرور الجديدة" class="input1" v-model="newPassword" required>
            <i :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'" @click="togglePasswordVisibility('password')" class="eye-icon"></i>
          </div>
          <div class="form-group d-flex flex-row code-box position-relative">
            <input :type="showConfirmPassword ? 'text' : 'password'" placeholder="تأكيد كلمة المرور" class="input1" v-model="confirmPassword" required>
            <i :class="showConfirmPassword ? 'bi bi-eye' : 'bi bi-eye-slash'" @click="togglePasswordVisibility('confirmPassword')" class="eye-icon"></i>
          </div>
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="form-group btn-form-container">
            <button type="submit" class="btn btn-block orange-btn">حفظ كلمة المرور</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '@/apiService'; // Ensure this path is correct
  
  export default {
    name: 'NewPassword',
    data() {
      return {
        email: this.$route.query.email || '',
        token: this.$route.query.token || '',
        newPassword: '',
        confirmPassword: '',
        errorMessage: '',
        showPassword: false,
        showConfirmPassword: false
      };
    },
    methods: {
      togglePasswordVisibility(field) {
        if (field === 'password') {
          this.showPassword = !this.showPassword;
        } else if (field === 'confirmPassword') {
          this.showConfirmPassword = !this.showConfirmPassword;
        }
      },
      async handleSubmit() {
        if (this.newPassword !== this.confirmPassword) {
          this.errorMessage = 'كلمات المرور غير متطابقة. حاول مرة أخرى.';
          return;
        }
        try {
          const response = await apiService.confirmPassword({
            email: this.email,
            token: this.token,
            password: this.newPassword,
            password_confirmation: this.confirmPassword,
          });
          console.log('Password changed successfully:', response.data);
          this.$router.push('/login'); // Redirect to the login page
        } catch (error) {
          console.error('Error changing password:', error);
          if (error.response && error.response.data && error.response.data.errors) {
            this.errorMessage = error.response.data.errors.password
              ? error.response.data.errors.password[0]
              : 'حدث خطأ. حاول مرة أخرى.';
          } else {
            this.errorMessage = 'حدث خطأ. حاول مرة أخرى.';
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .forms-container {
    height: 100vh;
  }
  .code-box {
    position: relative;
  }
  .eye-icon {
    position: absolute;
    left: 20px;
    top: 44%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #b9b9b9cf;
  }
  .bi-eye, .bi-eye-slash {
    font-size: 1.5rem;
  }
  </style>
  