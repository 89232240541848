<template>
  <div class="one-exam">
    <navbar></navbar>
     <Header title=" الامتحانات" />
     <open-exam></open-exam>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import OpenExam from '@/components/OpenExam.vue';

export default {
  name: 'OneExam',
  components: {
    Header,
    Navbar,
    OpenExam
  }
};
</script>
