<template>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
      <div class="achivements-container">
        <div class="col-lg-3 mt-4 mr-2 stats-card">
          <img :src="videoimg" class="stats-img" alt="image">
          <p class="stats-title">الفيديوهات المنجزة</p>
          <h1 class="stats-number">0</h1>
        </div>
        <div class="col-lg-3 mt-4 mr-2 stats-card">
          <img :src="examsimg" class="stats-img" alt="image">
          <p class="stats-title">الأمتحانات المنجزة </p>
          <h1 class="stats-number">0</h1>
        </div>
        <div class="col-lg-3 mt-4 mr-2 stats-card">
          <img :src="lessonsimg" class="stats-img" alt="image">
          <p class="stats-title"> الوجبات المنجزة </p>
          <h1 class="stats-number">0</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MonthlyAchivement',
  data() {
    return {
      videoimg: require('@/assets/images/video.png'),
      examsimg: require('@/assets/images/exams.png'),
      lessonsimg: require('@/assets/images/lessons.png')
    };
  }
};
</script>
<style scoped>
.tab-content-container {
  height: 439px;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  margin-top: 20px;
}

.content-title {
  color: #000;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
}

.profile-img {
  width: 90px;
  margin-right: 50px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 90px;
  float: right;
  background: lightgray;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-field {
  border: none;
  flex-shrink: 0;
  border-radius: 16px;
  background: #e6dede96;
  color: #808080;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}

.save-button {
  color: #FFF;
  text-align: center;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex-shrink: 0;
  border-radius: 16px;
  background: #FAAF43;
  border: none;
  padding: 10px 20px;
}
.achivements-container{
  display: flex;
}
.stats-card {
  margin: 10px;
  width: 30%;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.stats-img {
  margin-top: 20px;
  width: 57px;
  height: 57px;
  flex-shrink: 0;
}

.stats-title {
  color: #B3B3B3;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.stats-number {
  color: #000;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.message-container, .contact-container {
  width: 90%;
  height: 452px;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.message-input {
  border-radius: 16px;
  background: #FAFAFA;
  width: 96%;
  border: 1px none;
  display: block;
  height: 236px;
  flex-shrink: 0;
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.send-button {
  color: #FFF;
  text-align: center;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;
  border: 1px solid #FFF;
  border-radius: 16px;
  background: #FAAF43;
  width: 155px;
  height: 60px;
  flex-shrink: 0;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 20px;
}
@media (max-width: 700px){
  .achivements-container{
  flex-direction: column;
  align-items: center;
}
.stats-card{
  width: 90%;
}
}
</style>