<template>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
      <div class="col-12 tab-content-container">
        <h4 class="content-title"> البيانات الشخصية</h4>
        <div class="img-data-container">
          <div class="input-container">
            <!-- Check if userProfile is loaded -->
            <div v-if="userProfile" class="user-profile">
              <div class="d-flex flex-row">
                <div>
                  <label for="first_name"><strong>الاسم الاول:</strong></label>
                  <input id="first_name" v-model="updatedProfile.first_name" type="text" class="form-control" />
                </div>
                <div>
                  <label for="last_name"><strong>الاسم الثاني:</strong></label>
                  <input id="last_name" v-model="updatedProfile.last_name" type="text" class="form-control" />
                </div>
              </div>
              <div class="d-flex flex-row">
                <div>
                <label for="phone"><strong>رقم الهاتف:</strong></label>
                <input id="phone" v-model="updatedProfile.phone" type="text" class="form-control" />
              </div>
              <div>
                <label for="email"><strong>البريد الألكتروني:</strong></label>
                <input id="email" v-model="updatedProfile.email" type="text" class="form-control" />
              </div>
              </div>
              <div class="d-flex flex-row">
                <div>
                <label for="father_phone"><strong>رقم هاتف ولي الأمر:</strong></label>
                <input id="father_phone" v-model="updatedProfile.father_phone" type="text" class="form-control" />
              </div>
              <!-- <div>
                <label for="location"><strong>المحافظة:</strong></label>
                <select id="location" v-model="updatedProfile.location" class="form-control">
                  <option value="" disabled>اختر المحافظة</option>
                  <option v-for="location in locations" :key="location.id" :value="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div> -->
              <div>
                <label for="location"><strong>المحافظة:</strong></label>
                <p>{{ updatedProfile.location.name }}</p>
              </div>
              </div>
            </div>
            <!-- Show a loading message while the profile is being fetched -->
            <div v-else>
              <p>Loading profile...</p>
            </div>
            <!-- Save Changes Button -->
            <button class="save-button" @click="updateUserProfile">حفظ التغييرات</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/apiService'; // Make sure this path is correct based on your project structure

export default {
  name: 'ProfileData',
  data() {
    return {
      updatedProfile: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        father_phone: '',
        location: '', // This will hold the location ID
      },
      // locations: [] // Store the locations fetched from the API
    };
  },
  computed: {
    ...mapGetters(['userProfile']),
  },
  created() {
    if (!this.userProfile) {
      this.$store.dispatch('fetchUserProfile');
    } else {
      this.setProfileData();
    }
    // this.fetchLocations(); // Fetch locations when component is created
  },
  watch: {
    userProfile(newProfile) {
      this.setProfileData(); // Update the form fields when the user profile changes
    }
  },
  methods: {
    setProfileData() {
      if (this.userProfile) {
        this.updatedProfile = {
          first_name: this.userProfile.first_name || '',
          last_name: this.userProfile.last_name || '',
          phone: this.userProfile.phone || '',
          email: this.userProfile.email || '',
          father_phone: this.userProfile.father_phone || '',
          // location: this.userProfile.location ? this.userProfile.location.id : '', // Set default location ID
          location: this.userProfile.location || '', // Set default location ID
        };
      } else {
        // Reset form data if userProfile is null
        this.updatedProfile = {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          father_phone: '',
          location: '', // No location by default
        };
      }
    },
    // async fetchLocations() {
    //   try {
    //     const response = await apiService.getLocations(); // API call to fetch locations
    //     if (response && response.data) {
    //       this.locations = response.data.data; // Set the locations array
    //     } else {
    //       console.error('Invalid response format', response);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching locations', error);
    //   }
    // },
    async updateUserProfile() {
      try {
        const response = await apiService.updateProfile(this.updatedProfile);
        console.log('Profile updated successfully:', response);
        this.$store.dispatch('fetchUserProfile'); // Refresh the profile in Vuex after update
        alert('تم تحديث البيانات بنجاح');
      } catch (error) {
        console.error('Error updating profile:', error.response ? error.response.data : error.message);
        alert('حدث خطأ أثناء تحديث البيانات');
      }
    }
  }
};
</script>


<style scoped>
.user-profile p {
  border-radius: 6px;
  background: #FAFAFA;
  padding: 5px 15px;
  color: #808080;
  text-align: right;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* width: 45%; */
}

.user-profile div {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
}

.tab-content-container {
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  margin-top: 20px;
  padding: 20px 10px;
}

.content-title {
  color: #000;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-right: 20px; */
}

.profile-img {
  width: 90px;
  margin-right: 17px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 90px;
  float: right;
  background: lightgray;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-field {
  border: none;
  flex-shrink: 0;
  border-radius: 16px;
  background: #e6dede96;
  color: #808080;
  text-align: right;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}

.save-button {
  color: #FFF;
  text-align: center;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex-shrink: 0;
  border-radius: 16px;
  background: #FAAF43;
  border: none;
  padding: 10px 20px;
  width: 45%;
}

.stats-card {
  margin-top: 10px;
  width: 366px;
  height: 204px;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.stats-img {
  margin-top: 20px;
  width: 57px;
  height: 57px;
  flex-shrink: 0;
}

.stats-title {
  color: #B3B3B3;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.stats-number {
  color: #000;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.message-container,
.contact-container {
  width: 90%;
  height: 452px;
  flex-shrink: 0;
  border: 1px solid #F4F4F4;
  background: #FDFDFD;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.message-input {
  border-radius: 16px;
  background: #FAFAFA;
  width: 96%;
  border: 1px none;
  display: block;
  height: 236px;
  flex-shrink: 0;
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.send-button {
  color: #FFF;
  text-align: center;
  font-family: Cairo;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;
  border: 1px solid #FFF;
  border-radius: 16px;
  background: #FAAF43;
  width: 155px;
  height: 60px;
  flex-shrink: 0;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 20px;
}

.password-renew-container {
  width: 100%;
}

.password-renew-container input::placeholder {
  color: #808080;
  font-size: 18px;
}

.password-renew-container input {
  width: 45%;
  border-radius: 16px;
  background: #FAFAFA;
}
</style>
