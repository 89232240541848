<template>
  <div class="Exams">
    <navbar></navbar>
     <Header title=" الامتحانات" />
     <div class="content d-flex aside-row-complete">
      <ExamsAside @tab-selected="currentTab = $event" />
      <component :is="currentTab" class="exams-content"></component>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import NewExams from '@/components/NewExams.vue';
import ExamsAside from '@/components/ExamsAside.vue';
import CompletedExams from '@/components/CompletedExams.vue';
import FooterComponent from '@/components/FooterComponent';

export default {
  name: 'Exams',
  components: {
    Header,
    Navbar,
    NewExams,
    ExamsAside,
    CompletedExams,
    FooterComponent,
  },
  data() {
    return {
      currentTab: 'NewExams' // Initialize with NewExams component
    };
  }
};
</script>
