<template>
  <div class="w3-container" style="padding:30px 16px">
    <hr class="custom-hr" style="transform: rotate(180deg); margin-bottom: 0px;">
    <div class="w3-row-padding">
      <div class="w3-col m6">
        <img class="w3-image w3-large" :src="imageSrc" alt="Buildings" height="900">
      </div>
      <div class="w3-col m6 text-right mt-2">
        <h3 class="know-text" style="color: #0D0D0D; font-size: 40px; font-weight: 700;">
          عايز
          <span class="highlighted-text">تعرف </span>أكتر
        </h3>
        <p class="intro-text">اتعرف أكتر علي جيو أحمد مكاوي</p>
        <p class="description-text">من أكبر أساتذة مادة الجيولوجيا بمحافظة الدقهلية.. تخرج من كلية العلوم جامعة المنصورة عام
          2016، خبرة في تدريس مادة الجيولوجيا بأكبر السناتر التعليمية بجمهورية مصر العربية</p>
        <button class="w3-button btnhead2" @click="goToAboutPage">عرض الفيديو</button>
      </div> 
    </div>
    <hr class="custom-hr" style="margin-top: 0px;">
  </div>
</template>

<script>
export default {
  name: 'KnowMore',
  data() {
    return {
      imageSrc: require('@/assets/images/know-more.png')
    };
  },
   methods: {
    goToAboutPage() {
      this.$router.push('/about');
    }
  }
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

.custom-hr {
  background: linear-gradient(90deg, #FAAF43 50.21%, #FFF 82.34%);
  height: 1px;
  opacity: 1;
  border-top: 1px solid #eee;
}

.highlighted-text {
  color: #FAAF43;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* padding-left: 20px; */
  display: inline;
}

.intro-text {
  color: #0D0D0D;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
}

.description-text {
  color: #ADADAD;
  font-size: 20px;
  font-weight: 400;
  /* line-height: 60px; */
}

.btnhead2 {
  display: inline-flex;
  padding: 10px 30px;
  justify-content: left;
  align-items: left;
  border-radius: 32px;
  border: 1px solid #FAAF43;
  background: #FAAF43;
  color: white;
  font-size: 16px;
}
</style>
