<template>
    <div class="row">
        <log-aside></log-aside>
        <forget-password></forget-password>
    </div>
</template>

<script>
import LogAside from '@/components/LogAside.vue';
import ForgetPassword from '@/components/ForgetPassword.vue';

export default {
  name: 'ForgetPass',
  components: {
    LogAside,
    ForgetPassword
  }
};
</script>