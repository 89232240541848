<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <button @click="closeModal" class="close-btn">
          <img :src="closeImg">
        </button>
        <p>نتيجة الامتحان</p>
      </div>

      <div v-if="message" class="text-center p-5">{{ message }}</div>
      <div class="d-flex flex-column result-content" v-else>
        <div class="d-flex">
          <span>الامتحان: </span>
          <p>{{ exam_name }}</p>
        </div>
        <hr>
        <div class="d-flex">
          <span>درجة الامتحان: </span>
          <p>{{ exam_marks }}</p>
        </div>
        <hr>
        <div class="d-flex">
          <span>درجتك: </span>
          <p>{{ student_marks }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    examId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      closeImg: require('@/assets/images/close-circle.svg'),
      exam_name: '',
      exam_marks: '',
      student_marks: '',
      message: '', // To store error message if any
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.getExamResult();
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async getExamResult() {
      try {
        const response = await apiService.getExamResult(this.examId);
        // Reset the message if successful
        this.message = '';
        this.exam_name = response.data.exam_name || '';
        this.exam_marks = response.data.exam_marks !== undefined ? response.data.exam_marks : '';
        this.student_marks = response.data.student_marks !== undefined ? response.data.student_marks : '';
      } catch (error) {
        // Handle error, display message if exists in error response
        if (error.response && error.response.status === 403 && error.response.data.message) {
          this.message = error.response.data.message;
        } else {
          this.message = 'Error fetching exam result. Please try again later.';
        }
      }
    },
  },
};
</script>


    
    <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-start;
    background: #FAAF43;
    border-radius: 10px 10px 0 0;
    gap: 27%;
    padding-top: 10px;
    /* padding: 0 10px; */
    padding-right: 10px;
  }
  .modal-header p{
    color: #FFF;

font-family: Cairo;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  
  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0;
  }
  .close-btn img{
    width: 35px;
    height: 35px;
  }
.result-content{
    padding:20px 30px ;
}
  .result-content span{
    color: #FAAF43;

text-align: right;
font-family: Cairo;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 30%;
  }
  .result-content p{
    color: #000;
font-family: Cairo;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0;
  }
  </style>
    