<template>
  <div class="col-md-4 col-lg-4 left-container log-aside">
    <p>المنصة الأفضل في عالم الجيولوجيا</p>
  </div>
</template>

<script>
export default {
  name: 'LogAside',
};
</script>
